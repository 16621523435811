@import 'styles/animations';
@import 'styles/variables';
@import 'styles/mixins';

$list-breakpoint: 600px;

.planListTitle {
  margin-bottom: $spacing-unit * 2;

  @media (min-width: 440px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
}

.planListTitleText {
  font-weight: 700;
  font-size: pxrem(22);
}

.cancelLinkButton {
  color: $color-error;
  text-decoration: underline;
  cursor: pointer;
  background: transparent;
  border: none;
  appearance: none;

  font-size: pxrem(14);

  &:disabled {
    opacity: 0.75;
    cursor: not-allowed;
  }

  &:hover {
    color: $color-error-dark;
  }
}

.planName {
  font-weight: bold;
}

.planPrice {
  height: 3.25rem;
}

.planSavings {
  animation: fade-in 0.2s;
  display: block;
  font-size: pxrem(13);
  font-weight: 600;
  margin-top: 0.2rem;
  color: $color-secondary;
}

.planAction {
  width: pxrem(150);
  vertical-align: middle !important;
  text-align: right;
}

.planSubAction {
  vertical-align: middle !important;
  text-align: right;
}

.listOfPlans {
  margin-bottom: $spacing-unit * 3;
}

.listOfPlans__plan {
  display: flex;
  flex-direction: column;
  border-top: 1px solid $color-neutral-lighter;
  padding: $spacing-unit * 2 0;
  min-height: $spacing-unit * 5;
  width: 100%;

  &:last-of-type {
    border-bottom: 1px solid $color-neutral-lighter;
  }

  @media (min-width: $list-breakpoint) {
    flex-direction: row;
    align-items: center;
    min-height: $spacing-unit * 5;
  }
}

.listOfPlans__plan__details {
  width: 100%;
  order: 2;
  margin: $spacing-unit * 2 0 0 0;

  @media (min-width: $list-breakpoint) {
    margin: 0;
    flex-grow: 1;
    width: auto;
    order: 1;
  }
}

.listOfPlans__plan__name {
  display: inline-block;
  font-size: pxrem(18);
  line-height: 1.2;
  font-weight: 700;
  margin: 0;
  white-space: nowrap;
}

.listOfPlans__plan__nameContainer {
  display: flex;
  align-content: center;
  gap: 0.4rem;

  @media (max-width: 400px) {
    justify-content: space-between;
  }

  > fl-icon {
    @media (max-width: $breakpoint-mobile-max) {
      display: inline-block;
      margin: 8px 0 0 -3px;
    }
  }
}

.gardePromoContainer {
  display: inline-block;
  position: relative;
}

.gardePromo {
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 1rem rgba(#000, 0.1);
  font-size: 0.75rem;
  opacity: 0;
  overflow: hidden;
  padding: 6px 10px 10px;
  transition: 0.5s;
  white-space: nowrap;

  @media (max-width: $breakpoint-mobile-max) {
    margin: 0 0 0 20px;
    max-height: 0;
    right: 0;
    top: 0;
  }

  @media (min-width: $breakpoint-mobile-min) {
    margin-bottom: 0;
    max-width: 0;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
  }

  .gardePromoContainer:hover & {
    opacity: 1;

    @media (min-width: $breakpoint-mobile-min) {
      top: 24px;
      max-width: 400px;
    }

    @media (max-width: $breakpoint-mobile-max) {
      margin: 6px 0 20px 20px;
      max-height: 200px;
    }
  }

  fl-icon {
    display: inline-block;
    position: relative;
    top: 3px;
    vertical-align: middle;
  }
}

.listOfPlans__plan__nameContainer {
  display: flex;
  align-content: center;
  gap: 0.4rem;

  @media (max-width: 400px) {
    justify-content: space-between;
  }

  > fl-icon {
    @media (max-width: $breakpoint-mobile-max) {
      display: inline-block;
      margin: 8px 0 0 -3px;
    }
  }
}

.gardePromoContainer {
  display: inline-block;
  position: relative;
}

.gardePromo {
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 1rem rgba(#000, 0.1);
  font-size: 0.75rem;
  opacity: 0;
  overflow: hidden;
  padding: 6px 10px 10px;
  transition: 0.5s;
  white-space: nowrap;

  @media (max-width: $breakpoint-mobile-max) {
    margin: 0 0 0 20px;
    max-height: 0;
    right: 0;
    top: 0;
  }

  @media (min-width: $breakpoint-mobile-min) {
    margin-bottom: 0;
    max-width: 0;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
  }

  .gardePromoContainer:hover & {
    opacity: 1;

    @media (min-width: $breakpoint-mobile-min) {
      top: 24px;
      max-width: 400px;
    }

    @media (max-width: $breakpoint-mobile-max) {
      margin: 6px 0 20px 20px;
      max-height: 200px;
    }
  }

  fl-icon {
    display: inline-block;
    position: relative;
    top: 3px;
    vertical-align: middle;
  }
}

.listOfPlans__plan__description {
  color: $color-neutral-dark;
  font-size: pxrem(14);
  margin: $spacing-unit * 0.5 0 0;
}

.listOfPlans__plan__status {
  order: 1;
  flex-shrink: 0;

  @media (min-width: $list-breakpoint) {
    order: 2;
  }
}

.listOfPlans__plan__pricing {
  font-weight: 600;
  flex-shrink: 0;
  margin: $spacing-unit 0 0;
  order: 3;

  @media (min-width: $list-breakpoint) {
    font-weight: 400;
    text-align: right;
    margin: 0 0 0 $spacing-unit * 3;
  }
}

.listOfPlans__plan__price {
  display: inline-block;
  font-size: pxrem(14);
  line-height: 1.2;

  @media (min-width: $list-breakpoint) {
    font-size: pxrem(18);
    font-weight: 700;
    display: block;
  }
}

.listOfPlans__plan__billingPeriod {
  display: inline-block;
  font-size: pxrem(14);
  margin: 0 0 0 $spacing-unit * 0.5;

  @media (min-width: $list-breakpoint) {
    color: $color-neutral;
    display: block;
    font-size: pxrem(14);
    margin: $spacing-unit * 0.5 0 0;
  }
}

.listOfPlans__plan__actions {
  margin: $spacing-unit * 2 0 0;
  order: 4;

  @media (min-width: $list-breakpoint) {
    margin: 0 0 0 $spacing-unit * 4;
    text-align: center;
  }
}

.listOfPlans__plan__savings {
  font-size: pxrem(10);
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.1em;
  color: $color-secondary-dark;
  margin-top: $spacing-unit;
}

.discountBlock {
  text-align: center;
  margin-top: -2rem;
  margin-bottom: 2rem;
}
